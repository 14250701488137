import { apiRootURL } from "./defaultSettings";
export const DOCUMENT_TITLE = "卡蒂汽配 | ";

export const PROXY_PORT = {
    Opel: 3001,
    "07ZR": 4002,
    Michelin: 4003,
    Ford: 3004,
    RRG: 3005,
    GtEstimate: 8889,
};

export const dockApps = [
    "citroen",
    "ds",
    "opel",
    "peugeot",
    "misterauto",
    "eurorepar",
    "actia",
];

export const iframes = {
    citroen: "https://www.citroen.fr/accueil.html",
    ds: "https://www.dsautomobiles.fr/accueil.html",
    eurorepar: "https://www.eurorepar.fr/",
    misterauto: "https://www.mister-auto.com/",
    opel: process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.Opel,
    peugeot: "https://www.peugeot.fr/accueil.html",
    actia: "https://www.actia.com/fr",
    back: "https://webstore.bakelit.eu/",
    diag: "https://www.harsons.cn/",
    erp: "https://www.harsons.cn",
    store: "https://eshop-dev.ia.parts/vehicule",
    visio: "https://ncc.mx",
    com: "https://harson.n.parts/workshop/CarSelector",
    help: "https://www.harsons.cn",
    tecrmi: "https://rmi-athena.n.parts",
};

export const iframePartSelector = {
    fr: "https://www.harsons.cn",
    cn: "https://www.harsons.cn",
    zh: "https://www.harsons.cn",
    en: "https://www.harsons.cn",
    it: "https://www.harsons.cn/ie.php?http://www.nexusautomotive.it/",
    he: "https://www.mobilion-vc.com/",
    hi: "https://www.tvsautomobilesolutions.com/",
};

export const iframeCarSelector = {
    fr: "https://harson.n.parts/workshop/CarSelector/?mode=tablette",
    en: "https://harson.n.parts/workshop/CarSelector/?mode=tablette",
    cn: "https://harson.n.parts/workshop/CarSelector/?mode=tablette",
    hz: "https://harson.n.parts/workshop/CarSelector/?mode=tablette",
    it: "https://www.harsons.cn/ie.php?http://www.nexusautomotive.it/",
    he: "https://www.mobilion-vc.com/",
    hi: "https://www.tvsautomobilesolutions.com/Fleet/Index",
};

export const iframeContactSelector = {
    fr: "https://www.harsons.cn",
    cn: "https://www.harsons.cn",
    zh: "https://www.harsons.cn",
    en: "https://www.harsons.cn",
    it: "https://www.harsons.cn",
    he: "https://www.harsons.cn",
    hi: "https://www.harsons.cn",
};

export const DIAG_OTHER_BRANDS = [
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
];

export const PLUS_MENU_APPS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];

export const STOCK_IMAGE_URL = `https://medias.ia.parts/sivince/pimCE.png`;
export const CAIA_FULL_IMAGE_URL = `https://medias.ia.parts/sivince/vinCE.png`;
export const CAIA_GUII_IMAGE_URL = `https://medias.ia.parts/sivince/360IAM.png`;
export const CAIA_GUI_SELECTOR_IMAGE_URL = `https://medias.ia.parts/sivince/360OEM.png`;
export const TABLETTE_IMG = `${apiRootURL}/media/tab_icons/sivince.png`;
export const DATABOX_IMG = `${apiRootURL}/media/tab_icons/databox.png`;

export const LEVAM_URL = "https://oem1.ia.parts/oem_harson.html";
