import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { IMAGES_SERVER } from "@/config/settings";

export const CAIA_LOGO_URL = `${IMAGES_SERVER}/workshop/items/harson.png`;

export const iframeSlidesLogin = ["https://www.harsons.cn", "about:_blank"];
export const iframeSlides = [
    "https://www.harsons.cn",
    "http://www.citroen.fr/accueil.html",
    "http://www.dsautomobiles.fr/accueil.html",
];
export const iframeSlidesCaridentifier = [
    {
        title: "Meetbox",
        img: `${IMAGES_SERVER}/workshop/C@IA.png`,
        makeURL: (vd: VehicleData): string =>
            `https://ncc.mx/join/${vd.Codif_Vin_PRF}`,
    },
];
